import { Foundation } from 'foundation-sites';
import jquery from 'jquery';
window.jQuery = jquery;
window.$ = jquery;
Foundation.addToJquery($);

var focusedElementPriorModalOpen = undefined;
var allTabableElements = undefined;
var modalTabableElements = undefined;

(function () {
    
    "use strict";

    var app = {
        Device: "small",
        Html: $("html"),
        Body: $("body"),

        General: {
            init: function () {
                $(document).on("click", function (e) {
                    if (app.Body.hasClass('search-vissible')) {
                        app.Bootstrap.Search.close();
                    }
                    e.stopPropagation();
                });
            }
        },

        Foundation: {
            init: function () {
                $(document).foundation();

                // Set current breakpoint
                var breakpoint = Foundation.MediaQuery._getCurrentSize();
                app.Device = breakpoint === "small" || breakpoint === "medium" ? "small" : breakpoint;
            }
        },

        CookieInfo: {
            init: function () {



                $('body').on('click', function (e) {
                    var target = $(e.target);
                    if (target.hasClass('cc-dismiss')) {
                        e.preventDefault();
                        app.CookieInfo.Deny();
                    } else if (target.hasClass('cc-allow')) {
                        e.preventDefault();
                        app.CookieInfo.Accept();
                    }

                });

                // These elements are loaded after pageload, so the events are never triggered.
                //$(".cc-compliance .cc-allow").on("click", function (e) {
                //    e.preventDefault();
                //    app.CookieInfo.Accept();
                //});

                //$(".cc-compliance .cc-dismiss").on("click", function (e) {
                //    e.preventDefault();
                //    app.CookieInfo.Deny();
                //});
            },
            GetCookieDomain: function () {
                var originArray = window.location.origin.split(".");
                var cookieDomain = "." + originArray[originArray.length - 2] + "." + originArray[originArray.length - 1];
                return cookieDomain;
            },
            ClosePanel: function () {
                $("#js-cookie-info").addClass("cookie-info--hidden");
            },
            Accept: function () {
                app.CookieInfo.ClosePanel();
                Cookies.set('cookie-info', 'accepted', { expires: 365, path: '/', domain: app.CookieInfo.GetCookieDomain(), secure: false });
                location.reload();
            },
            Deny: function () {
                app.CookieInfo.ClosePanel();
                Cookies.set('cookie-info', 'denied', { expires: 365, path: '/', domain: app.CookieInfo.GetCookieDomain(), secure: false });
                app.CookieInfo.RemoveUnnecessaryCookies();

            },
            RemoveUnnecessaryCookies: function () {
                var cookies = Cookies.get();
                $.each(cookies, function (key) {
                    if (key != "cookieconsent_status" && key != "cookie-info") {
                        app.CookieInfo.DeleteCookie(key);
                    }
                });

            },
            DeleteCookie: function (name) {
                document.cookie = name + '=; Path=/; Domain=' + app.CookieInfo.GetCookieDomain() + '; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
            }
        },

        DrillDownNavigation: {
            menu: $('.main-menu-mobile'),
            init: function () {
                var $menu = app.DrillDownNavigation.menu,
                    $back = $menu.find('li.back'),
                    $forward = $menu.find('a span'),
                    startUl = $menu.find(".is-active").last(),
                    topMargin = 0;//75;

                if (startUl.height() === null || typeof startUI == 'undefined') {
                    $menu.css('height', $menu.children('ul:first-child').outerHeight());
                } else {
                    $menu.css('height', startUl.outerHeight());
                }

                $back.on('click', function (e) {
                    e.preventDefault();

                    var $this = $(this),
                        c = $this.closest('ul'),
                        d = c.parent().closest('ul');

                    $menu.css('height', d.outerHeight() + topMargin);

                    c.removeClass('is-active');
                    d.addClass('is-active');
                });

                $forward.on('click', function (e) {
                    e.preventDefault();
                    e.stopPropagation();

                    var $this = $(this),
                        d = $this.closest('li').find('ul').first();

                    $menu.css('height', d.outerHeight() + topMargin);
                    d.addClass('is-active');

                    app.DrillDownNavigation.scroll();
                });

            },
            scroll: function () {
                var topPos = $(window).scrollTop();
                if (topPos > 100) {
                    $('html, body').animate({
                        scrollTop: 0
                    }, 400);
                }
            },
            off: function () {
                $('.main-menu-mobile').css("height", "auto");
            }
        },


        Bootstrap: {
            Navigation: function () {
                $(".nav-open").click(function () {
                    $("body").toggleClass("mobile-nav-vissible");
                    app.DrillDownNavigation.init();
                });
            },
            Search: {
                searchPanel: $('#search'),
                init: function () {
                    $(".search-btn").on('click', function (e) {
                        app.Body.hasClass('search-vissible') ? app.Bootstrap.Search.close() : app.Bootstrap.Search.open();
                        e.stopPropagation();
                    });

                    app.Bootstrap.Search.searchPanel.on('click', function (e) {
                        e.stopPropagation();
                    });

                },
                open: function () {
                    app.Body.addClass("search-vissible");
                    $("#search").addClass("open");

                    app.Bootstrap.Search.searchPanel.on("transitionend webkitTransitionEnd oTransitionEnd", function (e) {
                        $("#search-input").focus();
                    });
                },
                close: function () {
                    app.Body.removeClass("search-vissible");
                    $("#search").removeClass("open");
                }
            },
            ScrollAnimation: function () {
                //AOS.init({
                //    once: true
                //});
            }
        },
        Navigation: {
            init: function () {
                $('.search-icon-container').on('click', function () {
                    var input = $('#siteSearchInput');
                    input.addClass('show');
                    input.focus();
                    input.attr('tabindex', '0');
                    $('.close-icon-container').attr('tabindex', '0');
                    $(this).attr('tabindex', '-1');
                });

                $('.close-icon-container').on('click', function () {
                    var input = $('#siteSearchInput');
                    input.removeClass('show');
                    input.attr('tabindex', '-1');
                    $(this).attr('tabindex', '-1');
                    $('.search-icon-container').attr('tabindex', '0');
                });

                $('.search-menu').on('keypress', function (e) {
                    if (e.keyCode == 13) {
                        e.preventDefault();
                        if ($('.search-icon-container').is(':focus')) {
                            $('.search-icon-container').trigger('click');
                        } else if ($('.close-icon-container').is(':focus')) {
                            $('.close-icon-container').trigger('click');
                        } else if ($('.menu-icon-container').is(':focus')) {
                            $('.menu-icon-container').trigger('click');
                        } else {
                            app.SearchPage.SiteSearch($('#siteSearchInput').val());
                        }
                    }
                });

                $('.mobile-navigation .show-children').on('click', function () {
                    var parent = $(this).parent();
                    var subMenu = parent.find('.mobile-sub-menu').first();
                    subMenu.addClass('show');
                    subMenu.find('a').first().focus();
                });

                $('.mobile-navigation .show-children').keydown(function (e) {
                    // enter
                    if (e.keyCode === 13) {
                        $(this).trigger('click');
                    }
                });

                $('.mobile-navigation .show-parent').on('click', function () {
                    var parent = $(this).parent().parent();
                    parent.removeClass('show');
                });

                $('.mobile-navigation .show-parent').keydown(function (e) {
                    // enter
                    if (e.keyCode === 13) {
                        $(this).trigger('click');
                    }
                });

                $('.mobile-nav-close').on('click', function () {
                    $('.mobile-navigation').removeClass('show');
                });

                $('.mobile-nav-close').keydown(function (e) {
                    // enter
                    if (e.keyCode === 13) {
                        $(this).trigger('click');
                    }
                });

                $('.menu-li').on('click', function () {
                    $('.mobile-navigation').addClass('show');
                    var openSubNavs = $('.mobile-sub-menu.show');
                    if (openSubNavs.length > 0) {
                        var shownNav = openSubNavs.eq(openSubNavs.length - 1);
                        shownNav.find('a').eq(0).focus();
                    }
                });
            }
        },
        SearchPage: {
            init: function () {
                app.SearchPage.SetTimer();

                $('#searchPageInput').on('keypress', function (e) {
                    if (e.keyCode == 13) {
                        setTimeout(function () { app.SearchPage.SetTimer() }, 1000);
                    }
                });
            },
            SiteSearch: function (search) {
                location.href = location.origin + "/sog?addsearch=" + search;
            },
            Timer: undefined,
            SetTimer: function () {
                app.SearchPage.CheckForSearchResults();
                app.SearchPage.Timer = setInterval(function () {
                    app.SearchPage.CheckForSearchResults();
                }, 250);
            },
            CheckForSearchResults: function () {
                var nohits = $('#addsearch-nohits');
                if (nohits.length > 0) {
                    nohits.find('p').eq(0).attr('role', 'alert');
                    clearInterval(app.SearchPage.Timer);
                }
                var results = $('.addsearch-result-item-container');
                if (results.length > 0) {
                    clearInterval(app.SearchPage.Timer);
                }
                var pages = $("#addsearch-rp-paging a");
                if (pages.length > 0) {
                    pages.on('click', function () {
                        app.SearchPage.SetTimer();
                    });
                    pages.each(function (index, link) {
                        link = $(link);
                        var visuallyHiddenElement = link.find('.visuallyhidden');
                        if (visuallyHiddenElement.length > 0) {
                            switch (visuallyHiddenElement.eq(0).html().trim()) {
                                case "previous":
                                    link.attr('aria-label', 'Tilbage til forrige side');
                                    break;
                                case "next":
                                    link.attr('aria-label', 'Videre til næste side');
                                    break;
                                case "current page":
                                    var clone = link.clone();
                                    clone.find('span').remove();
                                    link.attr('aria-label', 'Side ' + clone.html().trim());
                                    break;
                                default:
                            }
                        } else {
                            link.attr('aria-label', 'Side ' + link.html().trim());
                        }

                    });
                    clearInterval(app.SearchPage.Timer);
                }
            }
        },
        NewsListPage: {
            init: function () {
                app.NewsListPage.ReloadPageEvents();

                $('.tag-filters').change(function () {
                    var tag = $("input.filter-tag:checked");
                    $("input.filter-tag").removeAttr('checked');
                    tag[0].setAttribute('checked', '');
                    var tagId = tag.val();
                    var page = 1;


                    //var url = location.href.replace('#content', '');
                    var url = location.href.replace('#main', '');
                    var queryString = location.search;
                    var split = url.split('?')
                    if (queryString == "" && url.indexOf('?') > -1) {
                        queryString = split[1];
                    }
                    var newUrl = split[0];
                    var selectedAll = tagId == "0";
                    newUrl = (!selectedAll ? (newUrl + "?tag=" + tagId) : newUrl);

                    window.history.pushState('nyheder', 'Nyheder | LD Fonde', newUrl);

                    app.NewsListPage.RenderNewsList(tagId, page);
                });
            },
            RenderNewsList: function (tagId, page) {
                var sourceId = $('.news-list').attr('data-id');
                var currentScroll = $(document).scrollTop();
                var scrollTo = $('.news-list-container').position().top;
                if (scrollTo < currentScroll) {
                    $('html, body').animate({
                        scrollTop: scrollTo
                    }, 240);
                }
                $.get("/umbraco/surface/News/GetNewsList?sourceId=" + sourceId + "&tag=" + tagId + "&page=" + page, function (news) {
                    $('.news-list-container').html(news);
                    app.NewsListPage.ReloadPageEvents();
                });
            },
            PageChanged: function (pageLink) {
                var page = pageLink.html();
                var tagId = 0;
                //var url = location.href.replace('#content', '');
                var url = location.href.replace('#main', '');
                var queryString = location.search;
                if (queryString == "" && url.indexOf('?') > -1) {
                    queryString = url.split('?')[1];
                }
                var split = url.split('?')
                var newUrl = split[0] + "?page=" + page;
                if (queryString != "" && queryString.indexOf('tag') > -1) {
                    var params = split[1].split('&');
                    for (var i = 0; i < params.length; i++) {
                        var param = params[i];
                        if (param.indexOf('tag') > -1) {
                            newUrl += "&" + param;
                            tagId = param.split('=')[1];
                            break;
                        }
                    }
                }
                window.history.pushState('nyheder', 'Nyheder | LD Fonde', newUrl);

                $('.news-pagination a.page').removeClass('current-page');
                pageLink.addClass('current-page');

                app.NewsListPage.RenderNewsList(tagId, page);
            },
            ReloadPageEvents: function () {
                $('.news-pagination a.page').unbind('click').on('click', function () {
                    var pageLink = $(this);

                    app.NewsListPage.PageChanged(pageLink);
                });

                $('.news-pagination a.previous-page').unbind('click').on('click', function () {
                    var pageLink = $('.news-pagination a.current-page').prev();

                    app.NewsListPage.PageChanged(pageLink);
                });

                $('.news-pagination a.next-page').unbind('click').on('click', function () {
                    var pageLink = $('.news-pagination a.current-page').next();

                    app.NewsListPage.PageChanged(pageLink);
                });

                $('.news-pagination a').on('keyup', function (e) {
                    if (e.keyCode == 13) {
                        $(this).trigger('click');
                    }
                });
            }
        },
        DictionaryPage: {
            init: function () {
                var filterY;
                $(window).scroll(function () {
                    var topY = $(document).scrollTop();
                    filterY = ($('.filter-options').length > 0 ? $('.filter-options:eq(0)').offset().top : 0);
                    if (filterY > 0 && topY > filterY) {
                        $('.to-top').addClass('show');
                        var windowHeight = $(window).height();
                        var bottomY = topY + windowHeight;
                        var footerY = $('footer').offset().top;
                        if (bottomY > footerY) {
                            var gutterBottom = 26;
                            var toTopBottomValue = (bottomY - footerY) + gutterBottom;
                            $('.to-top').css('bottom', toTopBottomValue + "px");
                        } else {
                            $('.to-top').removeAttr('style');
                        }
                    } else {
                        $('.to-top').removeClass('show');
                    }
                });

                $('.to-top').on('click', function () {
                    $('html, body').animate({
                        scrollTop: filterY
                    }, 400);
                });

                $('.to-top').keydown(function (e) {
                    // enter
                    if (e.keyCode === 13) {
                        $(this).trigger('click');
                    }
                });
            }
        },
        ContentPage: {
            init: function () {
                $('.read-employee-desc').on('click', function () {
                    $(this).addClass('hidden');
                    var container = $(this).parent();
                    container.find('.employee-description').slideDown(240);
                });

                $('.read-employee-desc').on('keypress', function (e) {
                    if (e.keyCode == 13) {
                        $(this).trigger('click');
                    }
                });

                $('.employee-description .close-link').on('click', function () {
                    var container = $(this).parent();
                    if (container.hasClass('employee-description')) {
                        container.parent().find('.read-employee-desc').removeClass('hidden');
                        container.slideUp(240);
                    }
                });

                $('.employee-description .close-link').on('keypress', function (e) {
                    if (e.keyCode == 13) {
                        $(this).trigger('click');
                    }
                });

                $('.accordion-title').on('click', function () {
                    var label = $(this).attr('aria-label');
                    if (label.indexOf('Åbn') > -1) {
                        $(this).attr('aria-label', 'Luk indhold');
                    } else if (label.indexOf('Luk') > -1) {
                        $(this).attr('aria-label', 'Åbn indhold');
                    }
                });

                $('.print-link').on('click', function () {
                    window.print();
                });

                $('.print-link').on('keypress', function (e) {
                    if (e.keyCode == 13) {
                        $(this).trigger('click');
                    }
                });

                $('.video-overlay .load-video').on('click', function () {
                    var button = $(this);
                    var videoUrl = button.attr('data-video-url');
                    var container = button.parent().parent();

                    $.get(window.location.origin + "/umbraco/surface/video/render?url=" + button.attr('data-video-url'), function (response) {
                        container.replaceWith(response);
                    });
                });


                $('.video-overlay .load-video').on('keypress', function (e) {
                    if (e.keyCode == 13) {
                        $(this).trigger('click');
                    }
                });
            }
        },
        Modals: {
            init: function () {
                $('.close-modal').on('click', function () {
                    app.Modals.CloseModal();
                });

                $('.close-modal').keydown(function (e) {
                    // enter
                    if (e.keyCode === 13) {
                        $(this).trigger('click');
                    }
                });

                $('.open-modal').on('click', function () {
                    var modal = $(this).attr('data-target');
                    app.Modals.OpenModal(modal);
                });

                $('.open-modal').keydown(function (e) {
                    // enter
                    if (e.keyCode === 13) {
                        $(this).trigger('click');
                    }
                });

                $('.login-list-item a, nav.main-menu .login-button, .mobile-lang-login-container .login-button, .contact-info-modal a').on('click', function () {
                    var modal = $(this).attr('data-target');
                    app.Modals.OpenModal(modal);
                });

                $('.login-list-item a, nav.main-menu .login-button, .mobile-lang-login-container .login-button, .contact-info-modal a').keydown(function (e) {
                    // enter
                    if (e.keyCode === 13) {
                        $(this).trigger('click');
                    }
                });

                $('.switch-modal').on('click', function () {
                    app.Modals.CloseModal();
                    var modalId = $(this).attr('data-target');
                    var modalElem = $('.modal-container[data-id=' + modalId + ']')
                    app.Modals.OpenModal(modalElem.attr('id'));
                });

                $('.switch-modal').keydown(function (e) {
                    // enter
                    if (e.keyCode === 13) {
                        $(this).trigger('click');
                    }
                });
            },
            CloseModal: function (target) {
                var modal;
                if (target != undefined) {
                    modal = $('#' + target);
                } else {
                    modal = $('.modal-container');
                }
                modal.fadeOut(240);
                if (focusedElementPriorModalOpen != undefined) {
                    focusedElementPriorModalOpen.focus();
                }
                if (allTabableElements != undefined) {
                    allTabableElements.attr('tabindex', '0');
                }
                if (modalTabableElements != undefined) {
                    modalTabableElements.attr('tabindex', '-1');
                }
            },
            OpenModal: function (target) {
                var currentFocus = $(':focus');
                focusedElementPriorModalOpen = (currentFocus.length > 0 ? currentFocus.eq(0) : undefined);
                if (target != undefined) {
                    var modal = $('#' + target);
                    if (modal.length > 0) {
                        allTabableElements = $('a[href], area[href], input, select, textarea, button, iframe, object, embed, [tabindex], *[contenteditable]')
                            .not('[tabindex=-1], [disabled], :hidden');
                        modalTabableElements = modal.find('a[href], area[href], input, select, textarea, button, iframe, object, embed, [tabindex], *[contenteditable]')
                            .not('[tabindex=-1], [disabled], :hidden');
                        allTabableElements.attr('tabindex', '-1');
                        modalTabableElements.attr('tabindex', '0');
                        modal.fadeIn(240);
                        app.Modals.AddKeyEventsForModal(modal, modalTabableElements);
                    }
                }
            },
            AddKeyEventsForModal: function (modal, elements) {
                if (elements.length == 0 && elements.prevObject != undefined && elements.prevObject.length > 0) {
                    elements = elements.prevObject;
                }
                var currentIndex = 0;
                setTimeout(function () {
                    elements.eq(currentIndex).focus();
                }, 240);
                modal.unbind('keydown').on('keydown', function (e) {
                    if (e.keyCode == 9) { // TAB
                        e.preventDefault();
                        if (!e.shiftKey) {
                            currentIndex++;
                            if (currentIndex >= elements.length) {
                                currentIndex = 0;
                            }
                        } else {
                            currentIndex--;
                            if (currentIndex < 0) {
                                currentIndex = (elements.length - 1);
                            }
                        }
                        elements.eq(currentIndex).focus();
                    } else if (e.keyCode == 27) { // ESC
                        app.Modals.CloseModal(modal.attr('id'));
                    }
                });
            }
        },

        Charts: {
            init: function () {
                var charts = $('.chart-markup');
                $.each(charts, function (i, chart) {
                    chart = $(chart);
                    if (chart.hasClass('søjle')) {
                        app.Charts.InitializeBarChart(chart);
                    } else if (chart.hasClass('landskab')) {
                        app.Charts.InitializeLineChart(chart);
                    } else if (chart.hasClass('cirkel')) {
                        app.Charts.InitializeDonutChart(chart);
                    }
                    chart.remove(); // Removes markup used to get data for chart.
                });

                var returnTabChartLoader = $('#return-tab-chart-loader');

                if (returnTabChartLoader.length > 0) {
                    app.Charts.InitializeReturnTabChart(returnTabChartLoader);
                    app.Charts.AddReturnTabLineChartEventListeners();
                }
            },
            InitializeBarChart: function (markup) {
                var guid = markup.attr('data-guid');
                var hideYaixs = markup.attr('data-hide-yaxis') == "true";
                am4core.options.commercialLicense = true;
                var chart = am4core.create(guid, am4charts.XYChart);
                chart.language.locale["_decimalSeparator"] = ",";
                chart.language.locale["_thousandSeparator"] = ".";
                var data = [];
                var rows = markup.find('.chart-row');
                var categories = [];
                $.each(rows, function (j, row) {
                    row = $(row);
                    var rowData = {};
                    var cells = row.find('.chart-cell');
                    $.each(cells, function (k, cell) {
                        cell = $(cell);
                        if (j == 0) {
                            if (k > 0) {
                                categories.push({ index: k, identifier: ("value" + k), name: cell.html().trim() });
                            }
                        } else {
                            if (k == 0) {
                                rowData["year"] = cell.html().trim();
                            } else {
                                rowData["value" + k] = parseInt(cell.html().trim());
                            }
                        }
                    });
                    if (j > 0) {
                        data.push(rowData);
                    }
                });

                chart.data = data;

                // Create axes
                var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
                categoryAxis.dataFields.category = "year";
                var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
                if (hideYaixs) {
                    valueAxis.renderer.labels.template.disabled = true;
                }
                valueAxis.min = 0;

                // Create series
                function createSeries(field, name) {

                    // Set up series
                    var series = chart.series.push(new am4charts.ColumnSeries());
                    series.name = name;

                    series.dataFields.valueY = field;
                    series.dataFields.categoryX = "year";

                    // Make it stacked
                    series.stacked = true;

                    // Configure columns
                    series.columns.template.width = am4core.percent(60);
                    series.columns.template.tooltipText = "[bold]{name}[/]\n[font-size:14px]{categoryX}: {valueY}";

                    return series;
                }

                // Legend
                chart.legend = new am4charts.Legend();

                for (var i = 0; i < categories.length; i++) {
                    var category = categories[i];
                    createSeries(category.identifier, category.name);
                }

                chart.scrollbarX = new am4core.Scrollbar();
            },
            InitializeLineChart: function (markup) {
                var guid = markup.attr('data-guid');
                var hideYaixs = markup.attr('data-hide-yaxis') == "true";
                am4core.options.commercialLicense = true;
                var chart = am4core.create(guid, am4charts.XYChart);
                chart.language.locale["_decimalSeparator"] = ",";
                chart.language.locale["_thousandSeparator"] = ".";
                var data = [];
                var rows = markup.find('.chart-row');
                var axes = [];
                $.each(rows, function (j, row) {
                    row = $(row);
                    var rowData = {};
                    var cells = row.find('.chart-cell');
                    $.each(cells, function (k, cell) {
                        cell = $(cell);
                        if (j == 0) {
                            if (k > 0) {
                                axes.push({ identifier: ("value" + k), name: cell.html().trim() });
                            }
                        } else {
                            if (k == 0) {
                                if (cell.html().trim().length > 5) {
                                    var tmpDate = cell.html().trim().split("-");
                                    var date = new Date(tmpDate[2], tmpDate[1] - 1, tmpDate[0], '0', '0', '00');
                                } else {
                                    var date = new Date();
                                    date.setDate(1);
                                    date.setMonth(0);
                                    date.setHours(0, 0, 0, 0);
                                    date.setFullYear(cell.html().trim());
                                }
                                rowData["date"] = date;
                            } else {
                                rowData["value" + k] = parseInt(cell.html().trim());
                            }
                        }
                    });
                    if (j > 0) {
                        data.push(rowData);
                    }
                });

                chart.data = data;

                // Create axes
                var dateAxis = chart.xAxes.push(new am4charts.DateAxis());
                dateAxis.renderer.minGridDistance = 60;

                var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
                valueAxis.cursorTooltipEnabled = false;
                if (hideYaixs) {
                    valueAxis.renderer.labels.template.disabled = true;
                }
                chart.cursor = new am4charts.XYCursor();
                chart.cursor.xAxis = dateAxis;
                chart.cursor.yAxis = valueAxis;

                // Create series
                function createSeries(axis) {

                    var series = chart.series.push(new am4charts.LineSeries());
                    series.dataFields.valueY = axis.identifier;
                    series.dataFields.dateX = "date";
                    series.tooltipText = "[bold]" + axis.name + ":[/] {" + axis.identifier + "}";
                    series.name = axis.name;
                    series.strokeWidth = 2;


                    series.tooltip.pointerOrientation = "vertical";


                    return series;
                }

                // Legend

                chart.scrollbarX = new am4core.Scrollbar();
                chart.legend = new am4charts.Legend();

                for (var i = 0; i < axes.length; i++) {
                    var axis = axes[i];
                    createSeries(axis);
                }
            },
            InitializeDonutChart: function (markup) {
                var guid = markup.attr('data-guid')
                markup.after('<div class="chart" id="' + guid + '" style="height: 400px;"></div>');
                var configData = $.map(markup.find('.chart-row'), function (row, count) {
                    return { category: row.getAttribute('data-title'), value: parseFloat(row.getAttribute('data-value').replace(',', '.')) }
                });

                var chart = am4core.create(guid, am4charts.PieChart);
                chart.data = configData;
                chart.logo.disabled = true;
                chart.radius = "60%";
                chart.innerRadius = "40%";
                var pieSeries = chart.series.push(new am4charts.PieSeries());
                pieSeries.dataFields.value = "value";
                pieSeries.dataFields.category = "category";
                pieSeries.labels.template.text = "{category}\n{value.percent.formatNumber('#.0')} %";
                pieSeries.labels.template.fontWeight = "bold";
                pieSeries.labels.template.fill = "#474a66";
                pieSeries.tooltip.disabled = true;
                var slice = pieSeries.slices.template;
                slice.states.getKey("hover").properties.scale = 1;
                slice.states.getKey("active").properties.shiftRadius = 0;
            },
            InitializeReturnTabChart: function (loader) {
                var selectedYear = $('#returnTabLineChartSelector').val();
                var compare = $('#returnTabLineChartCompare').is(':checked');
                app.Charts.CreateReturnTabLineChart(loader, loader.attr('data-guid'), loader.attr('data-node-id'), loader.attr('data-x-axis-title'), loader.attr('data-y-axis-title'), loader.attr('data-index-label'), selectedYear, compare);

            },
            CreateReturnTabLineChart(container, guid, nodeId, xAxisTitle, yAxisTitle, indexLabel, selectedYear, compare) {
                $.get('/umbraco/surface/returntabcomponent/getlinegraphdata?nodeId=' + nodeId + '&selectedYear=' + selectedYear + '&compare=' + compare, function (res) {
                    container.replaceWith('<div class="chart return-tab-line-chart" id="' + guid + '" data-node-id="' + nodeId + '" data-x-axis-title="' + xAxisTitle + '" data-y-axis-title="' + yAxisTitle + '" data-index-label="' + indexLabel + '"></div>');

                    var chart = am4core.create(guid, am4charts.XYChart);
                    chart.logo.disabled = true;
                    chart.language.locale["_decimalSeparator"] = ",";
                    chart.language.locale["_thousandSeparator"] = ".";
                    chart.paddingTop = 40;
                    chart.data = res;
                    chart.paddingRight = 30;

                    var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
                    categoryAxis.dataFields.category = "Month";
                    var isSmallPage = $('.contentpage .main-content.medium-8').length > 0;
                    categoryAxis.renderer.minGridDistance = res.length * (isSmallPage ? 5 : 8);
                    categoryAxis.extraMin = 0;
                    categoryAxis.extraMax = 0;
                    if (xAxisTitle != "") {
                        categoryAxis.title.text = xAxisTitle;
                        categoryAxis.title.fontWeight = 600;
                        categoryAxis.title.fill = am4core.color("#474a66");
                        categoryAxis.title.paddingTop = 15;
                        categoryAxis.title.paddingBottom = 15;
                        categoryAxis.title.align = "right";
                    }
                    categoryAxis.renderer.line.strokeOpacity = 1;
                    categoryAxis.renderer.line.strokeWidth = 2;
                    categoryAxis.renderer.line.stroke = am4core.color("#474a66");
                    categoryAxis.startLocation = 0.5;
                    categoryAxis.endLocation = 0.55;

                    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
                    valueAxis.cursorTooltipEnabled = false;
                    valueAxis.layout = "absolute";
                    valueAxis.renderer.line.strokeOpacity = 1;
                    valueAxis.renderer.line.strokeWidth = 2;
                    valueAxis.renderer.line.stroke = am4core.color("#474a66");
                    valueAxis.renderer.line.dx = 63;
                    valueAxis.title.text = yAxisTitle;
                    valueAxis.title.rotation = 0;
                    valueAxis.title.align = "center";
                    valueAxis.title.valign = "top";
                    valueAxis.title.dy = -35;
                    valueAxis.title.dx = 63;
                    valueAxis.title.fontWeight = 600;
                    valueAxis.title.fill = am4core.color("#474a66");
                    valueAxis.title.isMeasured = false;
                    valueAxis.maxPrecision = 0;
                    var returnSeries = chart.series.push(new am4charts.LineSeries());
                    returnSeries.dataFields.valueY = "Return";
                    returnSeries.dataFields.categoryX = "Month";
                    returnSeries.name = "Feriemidler";
                    returnSeries.tooltipText = "[bold]{name}:[/] {valueY}";
                    returnSeries.legendSettings.valueText = "{valueY}";
                    returnSeries.strokeWidth = 2;
                    returnSeries.tooltip.pointerOrientation = "vertical";

                    if (compare) {
                        var priceIndexSeries = chart.series.push(new am4charts.LineSeries());
                        priceIndexSeries.dataFields.valueY = "Index";
                        priceIndexSeries.dataFields.categoryX = "Month";
                        priceIndexSeries.name = indexLabel;
                        priceIndexSeries.tooltipText = "[bold]{name}:[/] {valueY}";
                        priceIndexSeries.legendSettings.valueText = "{valueY}";
                        priceIndexSeries.strokeWidth = 2;
                        priceIndexSeries.tooltip.pointerOrientation = "vertical";
                    }

                    chart.cursor = new am4charts.XYCursor();

                    chart.legend = new am4charts.Legend();
                    chart.legend.valueLabels.template.disabled = true;
                });
            },
            AddReturnTabLineChartEventListeners() {
                var selector = $('#returnTabLineChartSelector');
                var checkbox = $('#returnTabLineChartCompare');
                var chartDescriptions = $('.return-tab-chart-description');
                selector.on('change', function () {
                    app.Charts.ReloadReturnTabLineChart();
                });
                checkbox.on('change', function () {
                    app.Charts.ReloadReturnTabLineChart();
                    chartDescriptions.toggle();
                });
            },
            ReloadReturnTabLineChart() {
                var selectedYear = $('#returnTabLineChartSelector').val();
                var compare = $('#returnTabLineChartCompare').is(':checked');
                var container = $('.return-tab-line-chart:eq(0)');
                var guid = container.attr('id');
                var nodeId = container.attr('data-node-id');
                var xAxisTitle = container.attr('data-x-axis-title');
                var yAxisTitle = container.attr('data-y-axis-title');
                var indexLabel = container.attr('data-index-label');
                app.Charts.CreateReturnTabLineChart(container, guid, nodeId, xAxisTitle, yAxisTitle, indexLabel, selectedYear, compare);
            },
            LoadLDTheme: function() {
                return function(target) {
                    if (target instanceof am4core.ColorSet) {
                        target.list = [
                            am4core.color("#474A66"),
                            am4core.color("#F38980"),
                            am4core.color("#003FDC"),
                            am4core.color("#B3C5E0"),
                            am4core.color("#006300"),
                            am4core.color("#C1ABA8"),
                            am4core.color("#E61300"),
                            am4core.color("#C8C9D1"),
                            am4core.color("#835652"),
                            am4core.color("#B3D0B3"),
                        ];
                    }
                };
            }
        }
    }

    app.General.init();
    app.Foundation.init();
    app.CookieInfo.init();
    app.Bootstrap.Navigation();
    app.Bootstrap.Search.init();
    app.Bootstrap.ScrollAnimation();
    app.Navigation.init();
    app.Modals.init();
    app.NewsListPage.init();
    app.DictionaryPage.init();
    app.SearchPage.init();
    app.ContentPage.init();

    document.addEventListener('DOMContentLoaded', function() {
        if (typeof am4core !== 'undefined') {
            am4core.ready(function () {
                am4core.useTheme(app.Charts.LoadLDTheme());
                app.Charts.init();
            });
        }
    })

})();